
































































import { Component, Watch, mixins } from 'nuxt-property-decorator'
import { mapState, mapGetters } from 'vuex'
import LogRocket from 'logrocket'
import MapFiltersV3 from '~/components/consumer_map_v3/MapFiltersV3.vue'
import MobileNavBar from '~/components/consumer_map_v3/MobileNavBar.vue'
import globalStyling from '~/mixins/globalStyling'
import { consumerMapStore } from '~/store'
import { storageAvailable } from '~/utils/localStorage'
import { Places } from '~/enums/heap'
import { Project } from '~/models'

@Component({
  middleware: ['logrocket'],
  mixins: [globalStyling],
  components: {
    MapFiltersV3,
    MobileNavBar,
  },
  computed: {
    ...mapState('consumer-map', {
      activeView: 'activeView',
    }),
    ...mapGetters('consumer-map', {
      project: 'project',
    }),
  },
})
export default class MapViewLayout extends mixins(globalStyling) {
  activeView: string
  project: Project
  mFilters: boolean = false
  mapActiveView: 'map' | 'grid' = 'map'

  mounted() {
    // @ts-ignore
    const url: string = this.$environmentConfig.baseURL
    if (url.includes('rpg') || url.includes('hillwood')) {
      LogRocket.identify('consumer-map-v3-user', {
        name: 'map user',
        email: 'mapv3@thexo.io',
        subscriptionType: 'pro',
      })
    }

    // this is used to catch a data sent from a map page under this layout
    this.$nuxt.$on('salesMap:v3:activeView', (value: 'map' | 'grid') => {
      this.mapActiveView = value
    })
  }

  beforeDestroy() {
    this.$nuxt.$off('salesMap:v3:activeView')
  }

  @Watch('project')
  onProjectChange() {
    // @ts-ignore
    if (this.$heap) this.$heap(null, null, this.project, Places.MAP_V3)
  }

  handleFilterChange(filters: any[]): void {
    if (
      this.$route.path.includes('favorites') ||
      this.$route.path.includes('shared-with-me')
    ) {
      return
    }
    if (consumerMapStore.debouncedFilteredRequestExist) {
      consumerMapStore.debouncedFilteredRequests(filters)
    }
  }

  seasonChange(value: boolean): void {
    this.$nuxt.$emit('salesMap:v3:seasonChanged', value)
  }

  get isStorageEnabled(): boolean {
    return storageAvailable()
  }

  showMobileFilters(value: boolean) {
    this.mFilters = value
  }
}
