import { getContracts as getBuilderContracts } from '~/api/builder.api'
import {
  getBuilderContracts as getNetBuilderContracts,
  getBuilders as getNetBuilders,
} from '~/api/v2/builder_contract.api'
import { getFilters } from '~/api/filters.api'
import { userStore } from '~/store'
import { Project } from '~/models'

const getContracts = async (builder_id?: number): Promise<any> => {
  if (!userStore.isContractsNetEnabled) {
    return await getBuilderContracts(builder_id)
  }

  const project: Project = userStore.project
  const queryFilters = `ProjectId=${project.id || 1}`

  let contracts: any = {}
  try {
    contracts = await getNetBuilderContracts(queryFilters)
  } catch (error) {
    throw new Error(`${error}`)
  }

  if (!builder_id) {
    return contracts.items
  }

  return (contracts.items || []).filter(
    (contract: any) => contract.builderId === builder_id
  )
}

const getBuilders = async (): Promise<any> => {
  if (!userStore.isContractsNetEnabled) {
    return await getFilters('builder_values')
  }

  const builders: any = await getNetBuilders()
  return builders.map((builder: any) => ({
    ...builder,
    value: builder.name,
  }))
}

export { getContracts, getBuilders }
