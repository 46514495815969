import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import BaseState from '~/models/state/baseState'

// Define a proper interface for the Project type
interface ProjectData {
  id: number
  google_tag_manager_id?: string
}

@Module({
  name: 'project',
  stateFactory: true,
  namespaced: true,
})
export default class Project extends VuexModule implements BaseState {
  // Make sure currentProject is of the correct type and can be null
  currentProject: ProjectData | null = null

  get activeProject(): number | null {
    return this.currentProject?.id || null
  }

  get googleTagManagerId(): string | null {
    return this.currentProject?.google_tag_manager_id || null
  }

  @Action({ rawError: true })
  changeActiveProject(project: ProjectData) {
    this.setActiveProject(project)
  }

  @Mutation
  setActiveProject(project: ProjectData) {
    this.currentProject = project
  }

  @Mutation
  clear(): void {
    this.currentProject = null
  }
}
